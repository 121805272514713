import {alertService} from "@/services/alert/alert.service";
import {newPasswordService} from "@/services/auth/new-password/new-password.service";

export default {
    data() {
        return {
            data: {
                lang: this.$i18n.locale
            },
            error: {},
            config: {},
            success: false,
            auth_type: 'facility'
        }
    },
    async mounted() {
        this.data.email = this.$route.params.email
        this.data.token = this.$route.params.token
    },
    methods: {


        async newPassword$() {

            await alertService.loading();
            await newPasswordService.newPassword(this.data).then((response) => {
                this.auth_type = response.data.auth_type == 'airport' ? 'facility' : 'guest'
                this.success = true
                alertService.close();
            }).catch((error) => {
                this.error = error
                alertService.close();
            });

        },
    }

}
