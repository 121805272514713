import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class NewPasswordService {
    newPassword(data) {
        promise =  api.client.post(config.end_point.auth.new_password, data);
        return promise;
    }
}

export const newPasswordService = new NewPasswordService();
